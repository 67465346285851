import { Box } from '@mui/material';
import React, { FC } from 'react';

import { assetProductTableColumns } from '#/components/campaigns/campaignAssets/assetsProduct/AssetProduct.tableColumns';
import CollapseComponent from '#/components/common/CollapseComponent/CollapseComponent';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import Loader from '#/components/common/loader/loader';
import { Table } from '#/components/common/table/tableComponent/Table';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import {
  ICampaignInstanceProductListData,
  ICampaignInstanceProductsListSingle,
} from '#/interfaces/campaigns/campaigns';

import {
  useGetCampaignInstanceTacticProductsAllQuery,
  useGetProductFeedFiltersQuery,
} from '#/store/api/products/products';

interface IAssetProductModalProps {
  instanceTacticId: string;
  selectedProduct: ICampaignInstanceProductsListSingle[];
  selectProduct: (newValue: ICampaignInstanceProductsListSingle[]) => void;
}
const AssetProductModal: FC<IAssetProductModalProps> = ({ instanceTacticId, selectedProduct, selectProduct }) => {
  const { data: filterData, isLoading: filterDataIsLoading } = useGetProductFeedFiltersQuery({});

  const { actions, state } = useSearchFilters(undefined, false, filterData?.filters);

  const { data, isLoading } = useGetCampaignInstanceTacticProductsAllQuery({
    instanceTacticId: instanceTacticId,
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    queryInput: state.searchQuery,
  });

  const formatData = (data?.products || []).map((item: ICampaignInstanceProductListData) => ({
    ...item.projection,
    selected: item.selected,
  }));

  if (isLoading || filterDataIsLoading) return <Loader />;

  return (
    <>
      {selectedProduct?.[0] && (
        <CollapseComponent
          actionTitle={
            <Box>
              <TypographyWithFontFamily variant="Default_Bold">Selected Product</TypographyWithFontFamily>
              <TypographyWithFontFamily variant="H5_Regular">
                {selectedProduct?.[0]?.universalProductCode}
              </TypographyWithFontFamily>
            </Box>
          }
          content={
            <Table
              columns={assetProductTableColumns(formatData, selectedProduct, selectProduct)}
              data={selectedProduct}
            />
          }
        />
      )}

      <Table
        columns={assetProductTableColumns(
          formatData,
          selectedProduct,
          selectProduct,
          filterData?.filters.map((column) => column.columnName) || [],
        )}
        data={formatData}
        sortProps={{
          sortParams: state.sortParams,
          setSortParams: actions.setSortParam,
        }}
      />
    </>
  );
};

export default AssetProductModal;
